export const FACTORY_ADDRESS = '0x8d973bAD782c1FFfd8FcC9d7579542BA7Dd0998D'

export const BUNDLE_ID = '1'

export const timeframeOptions = {
  WEEK: '1 week',
  MONTH: '1 month',
  // THREE_MONTHS: '3 months',
  // YEAR: '1 year',
  ALL_TIME: 'All time',
}

// token list urls to fetch tokens from - use for warnings on tokens and pairs
export const SUPPORTED_LIST_URLS__NO_ENS = [
  "https://asset.benswap.cash/dexdefault.json"
]

// hide from overview list
export const OVERVIEW_TOKEN_BLACKLIST = [

]

// pair blacklist
export const PAIR_BLACKLIST = []



export const SUPPORTED_LIST_LOCAL = {
  "name": "BenSwap Default List",
  "timestamp": "2021-06-05T19:09:29Z",
  "version": {
    "major": 1,
    "minor": 0,
    "patch": 0
  },
  "tags": {},
  "logoURI": "/coins/BCH.png",
  "keywords": ["benswap", "default"],
  "tokens": [
    {
      "name": "Wrapped BCH",
      "symbol": "WBCH",
      "address": "0x3743eC0673453E5009310C727Ba4eaF7b3a1cc04",
      "chainId": 10000,
      "decimals": 18
    },
    {
      "name": "Green Ben",
      "symbol": "EBEN",
      "address": "0x77CB87b57F54667978Eb1B199b28a0db8C8E1c0B",
      "chainId": 10000,
      "decimals": 18
    },
    {
      "name": "CashCats",
      "symbol": "$CATS",
      "address": "0x265bD28d79400D55a1665707Fa14A72978FA6043",
      "chainId": 10000,
      "decimals": 2
    }
  ]
}
